<template>
  
  <PageHeader :customer="customer" :instrument="instrument">

    <template v-slot:buttons>
      <ActionButtons :customer="customer" @showAction="showAction" />
    </template>

    <v-container fluid>

      <v-row v-if="action">
        <v-col cols="12">
          <Actions :customer="customer" :instrument="instrument" :action="action" :openLoans="openLoans" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab @click="changeTab(undefined)">{{customer.name}}</v-tab>
            <v-tab @click="changeTab(undefined)">New Loan</v-tab>
            <v-tab v-if="!products">
              <v-progress-linear indeterminate color="yellow" />
            </v-tab>
            <v-tab v-for="product in productsBalances" :key="product.id" @click="changeTab(product.id)" >
              {{instrumentTypeToName(product.type)}}
            </v-tab>
            
            <v-tab v-for="gift in instrumentGifts" :key="gift.id" @click="changeTab(gift.id)" >
              {{instrumentTypeToName(gift.type)}}
            </v-tab>
            <v-tab @click="changeTab(undefined)">History</v-tab>
            <v-tab @click="changeTab(undefined)">Attachments</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs-items v-model="tab">
            <v-tab-item><Landing :customer="customer" /></v-tab-item>
            <v-tab-item><NewLoan :customer="customer" :openLoans="products" /></v-tab-item>
            <v-tab-item v-for="balances in productsBalances" :key="balances.id" >
              <Instrument :customer="customer" :instrumentId="balances.id" v-model="instruments[balances.id]" />
            </v-tab-item>
            <v-tab-item v-for="gift in instrumentGifts" :key="gift.id">
              <Gift :customer="customer" :instrumentId="gift.id" v-model="gifts[gift.id]"/>
            </v-tab-item>
            <v-tab-item><History /></v-tab-item>
            <v-tab-item><Images :customer="customer" /></v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';
import instrumentNames from '@/mixins/instrumentNames';

import PageHeader from '@/views/partners/ultimate_tz/components/PageHeader';
import Landing from '@/views/customers/partners/ultimate_tz/components/Landing';
import NewLoan from '@/views/customers/partners/ultimate_tz/components/NewLoan';
import Images from '@/views/customers/partners/ultimate_tz/components/Images';
import Instrument from '@/views/customers/partners/ultimate_tz/components/Instrument';
import Actions from '@/views/customers/partners/ultimate_tz/components/Actions';
import ActionButtons from '@/views/customers/partners/ultimate_tz/components/ActionButtons';
import History from "@/views/instruments/History";
import Gift from '@/views/customers/partners/ultimate_tz/components/Gift'

export default {

  components: { PageHeader, Landing, NewLoan, Images, History, Instrument, Actions, ActionButtons, Gift },

  data() {
    return {
      tab: null,
      products: undefined,
      action: undefined,
      instrumentId: undefined,
      instruments: {},
      gifts: {},
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    productsBalances() { return ( this.products || [] ).filter(e => (e.type != "InstrumentGift") && (e.type != "InstrumentCommission")); },
    instrumentGifts() { return (this.products || []).filter(e => (e.type === "InstrumentGift") || (e.type === "InstrumentCommission"))},
    instrument() { return this.instruments[this.instrumentId]; },
    openLoans() { return this.productsBalances.length>0; },
  },

  watch: {},

  methods: {
    changeTab(to) {
      this.instrumentId = to;
    },
    showAction(action) {
      this.action = action;
    },
  },

  mounted() {
    this.remoteLookup('ultimate_tz_customer_balances', { uuid: this.uuid }).then( balances => this.products = balances.balances ).catch(e => this.products = null);
  },

  mixins: [lookups, instrumentNames],

}
</script>
