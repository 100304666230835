<template>
  <v-container>
    <Heading><span>{{ customer.name || 'Customer' }} is eligible for a <span v-if="instrument.type === 'InstrumentCommission'">commission of <Currency :value="cash" /></span> <span v-else>gift of {{ instrument.description }}</span></span></Heading>
      <div class="mt-10">
        <Heading>Verify your fingerprint to get your <span v-if="instrument.type === 'InstrumentCommission'">commission</span> <span v-else>gift</span></Heading>
        <Authorise    :customer="customer" v-model="authorised" @input="status = disburse"/>
      </div>
      
  </v-container>
</template>

<script>
import Heading from '@/components/Heading';
import Authorise from '@/views/partners/ultimate_tz/check_me_back/Authorise'
import lookups from '@/mixins/lookups';
import Currency from '@/components/Currency';

export default {

  components: { Heading, Authorise, Currency},
  props: {
    value: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    instrumentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      giftName: undefined,
      amount: undefined,
      quantity: undefined,
      status: "pending",
      authorised: undefined,
      instrument: undefined,
      cash: 10000,
    }
  },

  computed: {
    // disable() {
    //   return ((this.giftName === undefined) && (this.quantity === undefined) && (this.amount === undefined))
    // }
  },

  watch: {
    status(to) {
      if(to === this.disburse) {
        let lookupType = this.instrument.type === 'InstrumentCommission' ? 'ultimate_tz_disburse_commission' : 'ultimate_tz_disburse_gift'
        this.remoteLookup(lookupType, {instrumentId: (this.instrument.id || this.instrument.uuid)})
      }
    },
    instrument(to) { this.$emit('input', to); },
  },

  methods: {
  },

  mounted() {
    if (this.value) {
      this.instrument = this.value;
    } else {
      this.remoteLookup('instrument', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
      }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
    }
  },

  mixins: [lookups],


}
</script>